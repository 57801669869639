import { Injectable } from '@angular/core';
import {
  CreateDocumentTypeGQL,
  CreateDocumentTypeInput,
  CreateEmployeeGQL,
  CreateEmployeeInput,
  DeleteDocumentTypeGQL,
  DeleteDocumentTypeInput,
  DocumentTypeFilterInput,
  EmployeeDocumentFilterInput,
  EmployeeDocumentsGQL,
  EmployeeDocumentTypesGQL,
  EmployeeFilterInput,
  EmployeesGQL,
  EmployeeTeamsGQL,
  EventDashboardGQL,
  PublishEventTeamsInput,
  PublishTeamsGQL,
  UpdateDocumentTypeGQL,
  UpdateDocumentTypeInput,
  UpdateEmployeeStatusGQL,
  UpdateEmployeeStatusInput,
  EmployeeGQL,
  UpdateEmployeeGQL,
  UpdateEmployeeInput,
  InviteEmployeeGQL,
  InviteEmployeeInput,
  CreateEmployeeTeamGQL,
  CreateEmployeeTeamInput,
  UpdateEmployeeTeamGQL,
  UpdateEmployeeTeamInput,
  DeleteEmployeeTeamGQL,
  DeleteEmployeeTeamInput,
  PersonioGQL,
  ImportEmployeesGQL,
  ImportEmployeeInput
} from '../../generated/graphql';
import {EventService} from '../event/event.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private employeesGQL: EmployeesGQL,
    private employeeTeamsGQL: EmployeeTeamsGQL,
    private createEmployeeGQL: CreateEmployeeGQL,
    private updateStatusGQL: UpdateEmployeeStatusGQL,
    public employeeTypeDocumentsGQL: EmployeeDocumentTypesGQL,
    private createDocumentTypeGQL: CreateDocumentTypeGQL,
    private updateDocumentTypeGQL: UpdateDocumentTypeGQL,
    private deleteDocumentTypeGQL: DeleteDocumentTypeGQL,
    private publishTeamsGQL: PublishTeamsGQL,
    private eventDashboardGQL: EventDashboardGQL,
    private employeeDocumentsGQL: EmployeeDocumentsGQL,
    private employeeGQL: EmployeeGQL,
    private updateEmployeeGQL: UpdateEmployeeGQL,
    private inviteEmployeeGQL: InviteEmployeeGQL,
    private createEmployeeTeamGQL: CreateEmployeeTeamGQL,
    private updateEmployeeTeamGQL: UpdateEmployeeTeamGQL,
    private deleteEmployeeTeamGQL : DeleteEmployeeTeamGQL,
    private eventService: EventService,
    private personioGQL: PersonioGQL,
    private importEmployeesGQL: ImportEmployeesGQL
  ) { }


  importEmployees(input: ImportEmployeeInput) {
    return this.importEmployeesGQL.mutate({
      input
    },{
      refetchQueries: [
        {query: this.employeesGQL.document}
      ]
    });
  }
  createEmployee(input: CreateEmployeeInput) {
    return this.createEmployeeGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeesGQL.document
      }]
    })
  }

  syncPersonio() {
    return this.personioGQL.mutate(undefined,{
      refetchQueries: [{
        query: this.employeesGQL.document
      }]
    })
  }

  createTeam(input: CreateEmployeeTeamInput) {
    return this.createEmployeeTeamGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeeTeamsGQL.document
      }]
    })
  }

  deleteTeam(input: DeleteEmployeeTeamInput) {
    return this.deleteEmployeeTeamGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeeTeamsGQL.document
      }]
    })
  }

  updateTeam(input: UpdateEmployeeTeamInput) {
    return this.updateEmployeeTeamGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeeTeamsGQL.document
      }]
    })
  }

  inviteEmployee(input: InviteEmployeeInput) {
    return this.inviteEmployeeGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeesGQL.document
      }]
    })
  }

  updateEmployee(input: UpdateEmployeeInput) {
    return this.updateEmployeeGQL.mutate({
      input
    })
  }

  getEmployee(id: string) {
    return this.employeeGQL.watch({
      where: {
        id: {
          eq: id
        }
      }
    }).valueChanges
  }

  publishTeams(input: PublishEventTeamsInput) {
    return this.publishTeamsGQL.mutate({input},{
      // refetchQueries: [
      //   {query:this.eventService.eventGQL.document,
      //     variables: {
      //       where: {
      //         id: {
      //           eq: input.eventId
      //         }
      //       }
      //     }
      //   }
      // ]
    })
  }

  getDocumentTypes(where?: DocumentTypeFilterInput) {
    return this.employeeTypeDocumentsGQL.watch({
      where
    }).valueChanges;
  }

  getDocuments(id: string) {
    return this.employeeDocumentsGQL.watch({
      id
    }).valueChanges;
  }

  createDocumentType(input: CreateDocumentTypeInput) {
    return this.createDocumentTypeGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeeTypeDocumentsGQL.document
      }]
    })
  }

  updateDocumentType(input: UpdateDocumentTypeInput) {
    return this.updateDocumentTypeGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeeTypeDocumentsGQL.document
      }]
    })
  }

  deleteDocumentType(input: DeleteDocumentTypeInput) {
    return this.deleteDocumentTypeGQL.mutate({
      input
    },{
      refetchQueries: [{
        query: this.employeeTypeDocumentsGQL.document
      }]
    })
  }

  getEmployees(where?: EmployeeFilterInput) {
    return this.employeesGQL.watch({
      where
    }).valueChanges;
  }

  getTeams() {
    return this.employeeTeamsGQL.watch().valueChanges
  }

  updateStatus(input: UpdateEmployeeStatusInput) {
    return this.updateStatusGQL.mutate({
      input
    },)
  }
}
