import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, forwardRef, EventEmitter, inject, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, Input, HostBinding, Output, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i1 from 'primeng/ripple';
import { Ripple } from 'primeng/ripple';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import * as i2 from 'primeng/autofocus';
import { AutoFocus } from 'primeng/autofocus';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const _c0 = a0 => ({
  $implicit: a0
});
const _c1 = (a0, a1) => ({
  "p-togglebutton-icon": true,
  "p-togglebutton-icon-left": a0,
  "p-togglebutton-icon-right": a1
});
function ToggleButton_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ToggleButton_Conditional_2_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r0.checked ? ctx_r0.onIcon : ctx_r0.offIcon);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(4, _c1, ctx_r0.iconPos === "left", ctx_r0.iconPos === "right"));
    i0.ɵɵattribute("data-pc-section", "icon");
  }
}
function ToggleButton_Conditional_2_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ToggleButton_Conditional_2_Conditional_0_Conditional_0_Template, 1, 7, "span", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵconditional(ctx_r0.onIcon || ctx_r0.offIcon ? 0 : -1);
  }
}
function ToggleButton_Conditional_2_Conditional_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ToggleButton_Conditional_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ToggleButton_Conditional_2_Conditional_1_ng_container_0_Template, 1, 0, "ng-container", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.iconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.checked));
  }
}
function ToggleButton_Conditional_2_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 0);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r0.cx("label"));
    i0.ɵɵattribute("data-pc-section", "label");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.checked ? ctx_r0.hasOnLabel ? ctx_r0.onLabel : "" : ctx_r0.hasOffLabel ? ctx_r0.offLabel : "");
  }
}
function ToggleButton_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ToggleButton_Conditional_2_Conditional_0_Template, 1, 1)(1, ToggleButton_Conditional_2_Conditional_1_Template, 1, 4, "ng-container")(2, ToggleButton_Conditional_2_Conditional_2_Template, 2, 3, "span", 0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(!ctx_r0.iconTemplate ? 0 : 1);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(ctx_r0.onLabel || ctx_r0.offLabel ? 2 : -1);
  }
}
const theme = ({
  dt
}) => `
.p-togglebutton {
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    color: ${dt('togglebutton.color')};
    background: ${dt('togglebutton.background')};
    border: 1px solid ${dt('togglebutton.border.color')};
    padding: ${dt('togglebutton.padding')};
    font-size: 1rem;
    font-family: inherit;
    font-feature-settings: inherit;
    transition: background ${dt('togglebutton.transition.duration')}, color ${dt('togglebutton.transition.duration')}, border-color ${dt('togglebutton.transition.duration')},
        outline-color ${dt('togglebutton.transition.duration')}, box-shadow ${dt('togglebutton.transition.duration')};
    border-radius: ${dt('togglebutton.border.radius')};
    outline-color: transparent;
    font-weight: ${dt('togglebutton.font.weight')};
}

.p-togglebutton-content {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${dt('togglebutton.gap')};
}

.p-togglebutton-label,
.p-togglebutton-icon {
    position: relative;
    transition: none;
}

.p-togglebutton::before {
    content: "";
    background: transparent;
    transition: background ${dt('togglebutton.transition.duration')}, color ${dt('togglebutton.transition.duration')}, border-color ${dt('togglebutton.transition.duration')},
            outline-color ${dt('togglebutton.transition.duration')}, box-shadow ${dt('togglebutton.transition.duration')};
    position: absolute;
    left: ${dt('togglebutton.content.left')};
    top: ${dt('togglebutton.content.top')};
    width: calc(100% - calc(2 *  ${dt('togglebutton.content.left')}));
    height: calc(100% - calc(2 *  ${dt('togglebutton.content.top')}));
    border-radius: ${dt('togglebutton.border.radius')};
}

.p-togglebutton.p-togglebutton-checked::before {
    background: ${dt('togglebutton.content.checked.background')};
    box-shadow: ${dt('togglebutton.content.checked.shadow')};
}

.p-togglebutton:not(:disabled):not(.p-togglebutton-checked):hover {
    background: ${dt('togglebutton.hover.background')};
    color: ${dt('togglebutton.hover.color')};
}

.p-togglebutton.p-togglebutton-checked {
    background: ${dt('togglebutton.checked.background')};
    border-color: ${dt('togglebutton.checked.border.color')};
    color: ${dt('togglebutton.checked.color')};
}

.p-togglebutton:focus-visible {
    box-shadow: ${dt('togglebutton.focus.ring.shadow')};
    outline: ${dt('togglebutton.focus.ring.width')} ${dt('togglebutton.focus.ring.style')} ${dt('togglebutton.focus.ring.color')};
    outline-offset: ${dt('togglebutton.focus.ring.offset')};
}

.p-togglebutton.p-invalid {
    border-color: ${dt('togglebutton.invalid.border.color')};
}

.p-togglebutton:disabled {
    opacity: 1;
    cursor: default;
    background: ${dt('togglebutton.disabled.background')};
    border-color: ${dt('togglebutton.disabled.border.color')};
    color: ${dt('togglebutton.disabled.color')};
}

.p-togglebutton-icon {
    color: ${dt('togglebutton.icon.color')};
}

.p-togglebutton:not(:disabled):not(.p-togglebutton-checked):hover .p-togglebutton-icon {
    color: ${dt('togglebutton.icon.hover.color')};
}

.p-togglebutton.p-togglebutton-checked .p-togglebutton-icon {
    color: ${dt('togglebutton.icon.checked.color')};
}

.p-togglebutton:disabled .p-togglebutton-icon {
    color: ${dt('togglebutton.icon.disabled.color')};
}

/* For PrimeNG (iconPos) */

.p-togglebutton-icon-right {
    order: 1;
}

.p-togglebutton.ng-invalid.ng-dirty {
    border-color: ${dt('togglebutton.invalid.border.color')};
}
`;
const classes = {
  root: ({
    instance
  }) => ({
    'p-togglebutton p-component': true,
    'p-togglebutton-checked': instance.checked,
    'p-disabled': instance.disabled
  }),
  content: 'p-togglebutton-content',
  icon: 'p-togglebutton-icon',
  label: 'p-togglebutton-label'
};
class ToggleButtonStyle extends BaseStyle {
  name = 'togglebutton';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵToggleButtonStyle_BaseFactory;
    return function ToggleButtonStyle_Factory(__ngFactoryType__) {
      return (ɵToggleButtonStyle_BaseFactory || (ɵToggleButtonStyle_BaseFactory = i0.ɵɵgetInheritedFactory(ToggleButtonStyle)))(__ngFactoryType__ || ToggleButtonStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ToggleButtonStyle,
    factory: ToggleButtonStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToggleButtonStyle, [{
    type: Injectable
  }], null, null);
})();
const TOGGLEBUTTON_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ToggleButton),
  multi: true
};
/**
 * ToggleButton is used to select a boolean value using a button.
 * @group Components
 */
class ToggleButton extends BaseComponent {
  /**
   * Label for the on state.
   * @group Props
   */
  onLabel;
  /**
   * Label for the off state.
   * @group Props
   */
  offLabel;
  /**
   * Icon for the on state.
   * @group Props
   */
  onIcon;
  /**
   * Icon for the off state.
   * @group Props
   */
  offIcon;
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  ariaLabel;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  disabled;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Style class of the element.
   * @group Props
   */
  styleClass;
  get hostClass() {
    return this.styleClass || '';
  }
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  inputId;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex = 0;
  /**
   * Position of the icon.
   * @group Props
   */
  iconPos = 'left';
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus;
  /**
   * Whether selection can not be cleared.
   * @group Props
   */
  allowEmpty;
  /**
   * Callback to invoke on value change.
   * @param {ToggleButtonChangeEvent} event - Custom change event.
   * @group Emits
   */
  onChange = new EventEmitter();
  templates;
  iconTemplate;
  contentTemplate;
  checked = false;
  onModelChange = () => {};
  onModelTouched = () => {};
  _componentStyle = inject(ToggleButtonStyle);
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        case 'icon':
          this.iconTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }
  toggle(event) {
    if (!this.disabled && !(this.allowEmpty === false && this.checked)) {
      this.checked = !this.checked;
      this.onModelChange(this.checked);
      this.onModelTouched();
      this.onChange.emit({
        originalEvent: event,
        checked: this.checked
      });
      this.cd.markForCheck();
    }
  }
  onKeyDown(event) {
    switch (event.code) {
      case 'Enter':
        this.toggle(event);
        event.preventDefault();
        break;
      case 'Space':
        this.toggle(event);
        event.preventDefault();
        break;
    }
  }
  onBlur() {
    this.onModelTouched();
  }
  writeValue(value) {
    this.checked = value;
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  get hasOnLabel() {
    return this.onLabel && this.onLabel.length > 0;
  }
  get hasOffLabel() {
    return this.onLabel && this.onLabel.length > 0;
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵToggleButton_BaseFactory;
    return function ToggleButton_Factory(__ngFactoryType__) {
      return (ɵToggleButton_BaseFactory || (ɵToggleButton_BaseFactory = i0.ɵɵgetInheritedFactory(ToggleButton)))(__ngFactoryType__ || ToggleButton);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ToggleButton,
    selectors: [["p-toggleButton"]],
    contentQueries: function ToggleButton_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: ["role", "switch", "type", "button"],
    hostVars: 14,
    hostBindings: function ToggleButton_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("click", function ToggleButton_click_HostBindingHandler($event) {
          return ctx.toggle($event);
        })("keydown", function ToggleButton_keydown_HostBindingHandler($event) {
          return ctx.onKeyDown($event);
        });
      }
      if (rf & 2) {
        i0.ɵɵattribute("tabindex", ctx.disabled ? null : ctx.tabindex)("aria-checked", ctx.checked)("aria-labelledby", ctx.ariaLabelledBy)("aria-label", ctx.ariaLabel)("data-pc-name", ctx.toggleButton)("data-pc-section", ctx.root);
        i0.ɵɵclassMap(ctx.hostClass);
        i0.ɵɵclassProp("p-togglebutton", true)("p-togglebutton-checked", ctx.checked)("p-disabled", ctx.disabled);
      }
    },
    inputs: {
      onLabel: "onLabel",
      offLabel: "offLabel",
      onIcon: "onIcon",
      offIcon: "offIcon",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy",
      disabled: [2, "disabled", "disabled", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      inputId: "inputId",
      tabindex: [2, "tabindex", "tabindex", numberAttribute],
      iconPos: "iconPos",
      autofocus: [2, "autofocus", "autofocus", booleanAttribute],
      allowEmpty: "allowEmpty"
    },
    outputs: {
      onChange: "onChange"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([TOGGLEBUTTON_VALUE_ACCESSOR, ToggleButtonStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵHostDirectivesFeature([i1.Ripple, {
      directive: i2.AutoFocus,
      inputs: ["autofocus", "autofocus"]
    }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 3,
    vars: 6,
    consts: [[3, "ngClass"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "class", "ngClass"]],
    template: function ToggleButton_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "span", 0);
        i0.ɵɵtemplate(1, ToggleButton_ng_container_1_Template, 1, 0, "ng-container", 1)(2, ToggleButton_Conditional_2_Template, 3, 2);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngClass", ctx.cx("content"));
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.contentTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(4, _c0, ctx.checked));
        i0.ɵɵadvance();
        i0.ɵɵconditional(!ctx.contentTemplate ? 2 : -1);
      }
    },
    dependencies: [SharedModule, CommonModule, i3.NgClass, i3.NgTemplateOutlet],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToggleButton, [{
    type: Component,
    args: [{
      selector: 'p-toggleButton',
      template: `
        <span [ngClass]="cx('content')">
            <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: checked }"></ng-container>
            @if (!contentTemplate) {
                @if (!iconTemplate) {
                    @if (onIcon || offIcon) {
                        <span
                            [class]="checked ? this.onIcon : this.offIcon"
                            [ngClass]="{
                                'p-togglebutton-icon': true,
                                'p-togglebutton-icon-left': iconPos === 'left',
                                'p-togglebutton-icon-right': iconPos === 'right',
                            }"
                            [attr.data-pc-section]="'icon'"
                        ></span>
                    }
                } @else {
                    <ng-container *ngTemplateOutlet="iconTemplate; context: { $implicit: checked }"></ng-container>
                }
                @if (onLabel || offLabel) {
                    <span [ngClass]="cx('label')" [attr.data-pc-section]="'label'">{{
                        checked ? (hasOnLabel ? onLabel : '') : hasOffLabel ? offLabel : ''
                    }}</span>
                }
            }
        </span>
    `,
      standalone: true,
      imports: [Ripple, AutoFocus, SharedModule, CommonModule],
      providers: [TOGGLEBUTTON_VALUE_ACCESSOR, ToggleButtonStyle],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        role: 'switch',
        type: 'button',
        '(click)': 'toggle($event)',
        '(keydown)': 'onKeyDown($event)',
        '[attr.tabindex]': 'disabled ? null : tabindex',
        '[attr.aria-checked]': 'checked',
        '[attr.aria-labelledby]': 'ariaLabelledBy',
        '[attr.aria-label]': 'ariaLabel',
        '[attr.data-pc-name]': 'toggleButton',
        '[attr.data-pc-section]': 'root',
        '[class.p-togglebutton.p-component]': 'true',
        '[class.p-togglebutton-checked]': 'checked',
        '[class.p-disabled]': 'disabled'
      },
      hostDirectives: [Ripple, {
        directive: AutoFocus,
        inputs: ['autofocus']
      }]
    }]
  }], null, {
    onLabel: [{
      type: Input
    }],
    offLabel: [{
      type: Input
    }],
    onIcon: [{
      type: Input
    }],
    offIcon: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }],
    inputId: [{
      type: Input
    }],
    tabindex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    iconPos: [{
      type: Input
    }],
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    allowEmpty: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class ToggleButtonModule {
  static ɵfac = function ToggleButtonModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ToggleButtonModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ToggleButtonModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [ToggleButton, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToggleButtonModule, [{
    type: NgModule,
    args: [{
      imports: [ToggleButton],
      exports: [ToggleButton, SharedModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TOGGLEBUTTON_VALUE_ACCESSOR, ToggleButton, ToggleButtonModule };
