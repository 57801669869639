import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const _c0 = ["*"];
const theme = ({
  dt
}) => `
/* For PrimeNG */
.p-iconfield {
    position: relative;
}

.p-iconfield > p-inputicon {
    position: absolute;
    top: 50%;
    margin-top: calc(-1 * (${dt('icon.size')} / 2));
}

.p-iconfield-left {
    color: ${dt('iconfield.icon.color')};
}

.p-iconfield-right {
    color: ${dt('iconfield.icon.color')};
}

.p-iconfield.p-iconfield-left > .p-inputtext {
    padding-left: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

.p-iconfield.p-iconfield-right > .p-inputtext {
    padding-right: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

.p-iconfield.p-iconfield-left > p-inputicon {
    left: ${dt('form.field.padding.x')};
}

.p-iconfield.p-iconfield-right > p-inputicon {
    right: ${dt('form.field.padding.x')};
}
`;
const classes = {
  root: 'p-iconfield'
};
class IconFieldStyle extends BaseStyle {
  name = 'iconfield';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵIconFieldStyle_BaseFactory;
    return function IconFieldStyle_Factory(__ngFactoryType__) {
      return (ɵIconFieldStyle_BaseFactory || (ɵIconFieldStyle_BaseFactory = i0.ɵɵgetInheritedFactory(IconFieldStyle)))(__ngFactoryType__ || IconFieldStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: IconFieldStyle,
    factory: IconFieldStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconFieldStyle, [{
    type: Injectable
  }], null, null);
})();

/**
 * IconField wraps an input and an icon.
 * @group Components
 */
class IconField extends BaseComponent {
  /**
   * Position of the icon.
   * @group Props
   */
  iconPosition = 'left';
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  _componentStyle = inject(IconFieldStyle);
  get containerClass() {
    return {
      'p-iconfield-left': this.iconPosition === 'left',
      'p-iconfield-right': this.iconPosition === 'right'
    };
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵIconField_BaseFactory;
    return function IconField_Factory(__ngFactoryType__) {
      return (ɵIconField_BaseFactory || (ɵIconField_BaseFactory = i0.ɵɵgetInheritedFactory(IconField)))(__ngFactoryType__ || IconField);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: IconField,
    selectors: [["p-iconfield"], ["p-iconField"]],
    inputs: {
      iconPosition: "iconPosition",
      styleClass: "styleClass"
    },
    features: [i0.ɵɵProvidersFeature([IconFieldStyle]), i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 2,
    vars: 3,
    consts: [[1, "p-iconfield", 3, "ngClass"]],
    template: function IconField_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "span", 0);
        i0.ɵɵprojection(1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", ctx.containerClass);
      }
    },
    dependencies: [i1.NgClass],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconField, [{
    type: Component,
    args: [{
      selector: 'p-iconfield, p-iconField',
      template: ` <span class="p-iconfield" [ngClass]="containerClass" [class]="styleClass"><ng-content></ng-content> </span>`,
      providers: [IconFieldStyle],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    iconPosition: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }]
  });
})();
class IconFieldModule {
  static ɵfac = function IconFieldModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IconFieldModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: IconFieldModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconFieldModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [IconField, SharedModule],
      declarations: [IconField]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IconField, IconFieldModule };
