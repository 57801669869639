import * as i0 from '@angular/core';
import { Directive, Injectable, inject, Component, ChangeDetectionStrategy, ViewEncapsulation, SkipSelf, Optional, ViewChild, NgModule, createComponent, Inject } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { animation, style, animate, trigger, transition, useAnimation } from '@angular/animations';
import * as i3 from '@angular/common';
import { isPlatformBrowser, CommonModule, DOCUMENT } from '@angular/common';
import { TranslationKeys, SharedModule } from 'primeng/api';
import * as i5 from 'primeng/focustrap';
import { FocusTrapModule } from 'primeng/focustrap';
import { TimesIcon } from 'primeng/icons/times';
import { WindowMaximizeIcon } from 'primeng/icons/windowmaximize';
import { WindowMinimizeIcon } from 'primeng/icons/windowminimize';
import { UniqueComponentId, ZIndexUtils } from 'primeng/utils';
import { BaseComponent } from 'primeng/basecomponent';
import { DialogStyle } from 'primeng/dialog';
import * as i4 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { Subject } from 'rxjs';
const _c0 = ["mask"];
const _c1 = ["content"];
const _c2 = ["footer"];
const _c3 = ["titlebar"];
const _c4 = (a0, a1) => ({
  transform: a0,
  transition: a1
});
const _c5 = a0 => ({
  value: "visible",
  params: a0
});
function DynamicDialogComponent_div_2_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵlistener("mousedown", function DynamicDialogComponent_div_2_div_2_Template_div_mousedown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.initResize($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("resizeHandle"));
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_1_WindowMaximizeIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "WindowMaximizeIcon");
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_1_WindowMinimizeIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "WindowMinimizeIcon");
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_1_WindowMaximizeIcon_1_Template, 1, 0, "WindowMaximizeIcon", 10)(2, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_1_WindowMinimizeIcon_2_Template, 1, 0, "WindowMinimizeIcon", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.maximized && !ctx_r1.maximizeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.maximized && !ctx_r1.minimizeIconTemplate);
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_2_1_ng_template_0_Template(rf, ctx) {}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_2_1_Template, 1, 0, null, 20);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1._maximizeIconTemplate);
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_3_1_ng_template_0_Template(rf, ctx) {}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_3_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_3_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_3_1_Template, 1, 0, null, 20);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1._minimizeIconTemplate);
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 19);
    i0.ɵɵlistener("onClick", function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_Template_p_button_onClick_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.maximize());
    })("keydown.enter", function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_Template_p_button_keydown_enter_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.maximize());
    });
    i0.ɵɵtemplate(1, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_1_Template, 3, 2, "ng-container", 10)(2, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_2_Template, 2, 1, "ng-container", 10)(3, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_ng_container_3_Template, 2, 1, "ng-container", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("styleClass", ctx_r1.cx("pcMaximizeButton"))("tabindex", ctx_r1.maximizable ? "0" : "-1");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.maximizeIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.maximized);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.maximized);
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "TimesIcon");
    i0.ɵɵelementContainerEnd();
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_span_2_1_ng_template_0_Template(rf, ctx) {}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtemplate(1, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_span_2_1_Template, 1, 0, null, 20);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.closeIconTemplate);
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 21);
    i0.ɵɵlistener("onClick", function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_Template_p_button_onClick_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.hide());
    })("keydown.enter", function DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_Template_p_button_keydown_enter_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.hide());
    });
    i0.ɵɵtemplate(1, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_ng_container_1_Template, 2, 0, "ng-container", 10)(2, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_span_2_Template, 2, 1, "span", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("styleClass", ctx_r1.cx("pcCloseButton"))("ariaLabel", ctx_r1.closeAriaLabel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.closeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.closeIconTemplate);
  }
}
function DynamicDialogComponent_div_2_div_3_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 15);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 16);
    i0.ɵɵtemplate(4, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_4_Template, 4, 5, "p-button", 17)(5, DynamicDialogComponent_div_2_div_3_ng_container_3_p_button_5_Template, 3, 4, "p-button", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", ctx_r1.cx("title"))("id", ctx_r1.ariaLabelledBy);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.ddconfig.header);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", ctx_r1.cx("headerActions"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.ddconfig.maximizable);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.closable);
  }
}
function DynamicDialogComponent_div_2_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 14, 3);
    i0.ɵɵlistener("mousedown", function DynamicDialogComponent_div_2_div_3_Template_div_mousedown_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.initDrag($event));
    });
    i0.ɵɵtemplate(2, DynamicDialogComponent_div_2_div_3_ng_container_2_Template, 1, 0, "ng-container", 11)(3, DynamicDialogComponent_div_2_div_3_ng_container_3_Template, 6, 6, "ng-container", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("header"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngComponentOutlet", ctx_r1.headerTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.headerTemplate);
  }
}
function DynamicDialogComponent_div_2_6_ng_template_0_Template(rf, ctx) {}
function DynamicDialogComponent_div_2_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, DynamicDialogComponent_div_2_6_ng_template_0_Template, 0, 0, "ng-template", 22);
  }
}
function DynamicDialogComponent_div_2_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function DynamicDialogComponent_div_2_div_8_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.ddconfig.footer, " ");
  }
}
function DynamicDialogComponent_div_2_div_8_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function DynamicDialogComponent_div_2_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 16, 4);
    i0.ɵɵtemplate(2, DynamicDialogComponent_div_2_div_8_ng_container_2_Template, 2, 1, "ng-container", 10)(3, DynamicDialogComponent_div_2_div_8_ng_container_3_Template, 1, 0, "ng-container", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("footer"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.footerTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngComponentOutlet", ctx_r1.footerTemplate);
  }
}
function DynamicDialogComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7, 1);
    i0.ɵɵlistener("@animation.start", function DynamicDialogComponent_div_2_Template_div_animation_animation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onAnimationStart($event));
    })("@animation.done", function DynamicDialogComponent_div_2_Template_div_animation_animation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onAnimationEnd($event));
    });
    i0.ɵɵtemplate(2, DynamicDialogComponent_div_2_div_2_Template, 1, 1, "div", 8)(3, DynamicDialogComponent_div_2_div_3_Template, 4, 3, "div", 9);
    i0.ɵɵelementStart(4, "div", 5, 2);
    i0.ɵɵtemplate(6, DynamicDialogComponent_div_2_6_Template, 1, 0, null, 10)(7, DynamicDialogComponent_div_2_ng_container_7_Template, 1, 0, "ng-container", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, DynamicDialogComponent_div_2_div_8_Template, 4, 3, "div", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(ctx_r1.ddconfig.style);
    i0.ɵɵclassMap(ctx_r1.ddconfig.styleClass);
    i0.ɵɵstyleProp("width", ctx_r1.ddconfig.width)("height", ctx_r1.ddconfig.height);
    i0.ɵɵproperty("ngClass", ctx_r1.cx("root"))("ngStyle", ctx_r1.sx("root"))("@animation", i0.ɵɵpureFunction1(24, _c5, i0.ɵɵpureFunction2(21, _c4, ctx_r1.transformOptions, ctx_r1.ddconfig.transitionOptions || "150ms cubic-bezier(0, 0, 0.2, 1)")))("pFocusTrapDisabled", ctx_r1.ddconfig.focusTrap === false);
    i0.ɵɵattribute("aria-labelledby", ctx_r1.ariaLabelledBy)("aria-modal", true);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.ddconfig.resizable);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.ddconfig.showHeader === false ? false : true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", ctx_r1.cx("content"))("ngStyle", ctx_r1.ddconfig.contentStyle);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.contentTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngComponentOutlet", ctx_r1.contentTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.ddconfig.footer || ctx_r1.footerTemplate);
  }
}
class DynamicDialogContent {
  viewContainerRef;
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  static ɵfac = function DynamicDialogContent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DynamicDialogContent)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: DynamicDialogContent,
    selectors: [["", "pDynamicDialogContent", ""]]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicDialogContent, [{
    type: Directive,
    args: [{
      selector: '[pDynamicDialogContent]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }], null);
})();
class DynamicDialogStyle extends DialogStyle {
  name = 'dialog';
  static ɵfac = /* @__PURE__ */(() => {
    let ɵDynamicDialogStyle_BaseFactory;
    return function DynamicDialogStyle_Factory(__ngFactoryType__) {
      return (ɵDynamicDialogStyle_BaseFactory || (ɵDynamicDialogStyle_BaseFactory = i0.ɵɵgetInheritedFactory(DynamicDialogStyle)))(__ngFactoryType__ || DynamicDialogStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DynamicDialogStyle,
    factory: DynamicDialogStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicDialogStyle, [{
    type: Injectable
  }], null, null);
})();

/**
 * Dialogs can be created dynamically with any component as the content using a DialogService.
 * @group Components
 */
class DynamicDialogConfig {
  /**
   * An object to pass to the component loaded inside the Dialog.
   * @group Props
   */
  data;
  /**
   * Header text of the dialog.
   * @group Props
   */
  header;
  /**
   * Identifies the element (or elements) that labels the element it is applied to.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Footer text of the dialog.
   * @group Props
   */
  footer;
  /**
   * Width of the dialog.
   * @group Props
   */
  width;
  /**
   * Height of the dialog.
   * @group Props
   */
  height;
  /**
   * Specifies if pressing escape key should hide the dialog.
   * @group Props
   */
  closeOnEscape;
  /**
   * Specifies if autofocus should happen on show.
   * @group Props
   */
  focusOnShow = true;
  /**
   * When enabled, can only focus on elements inside the dialog.
   * @group Props
   */
  focusTrap = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex;
  /**
   * Specifies if clicking the modal background should hide the dialog.
   * @group Props
   */
  dismissableMask;
  /**
   * Inline style of the component.
   * @group Props
   */
  rtl;
  /**
   * Inline style of the comopnent.
   * @group Props
   */
  style;
  /**
   * Inline style of the content.
   * @group Props
   */
  contentStyle;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Transition options of the animation.
   * @group Props
   */
  transitionOptions;
  /**
   * Adds a close icon to the header to hide the dialog.
   * @group Props
   */
  closable;
  /**
   * Whether to show the header or not.
   * @group Props
   */
  showHeader;
  /**
   * Defines if background should be blocked when dialog is displayed.
   * @group Props
   */
  modal;
  /**
   * Style class of the mask.
   * @group Props
   */
  maskStyleClass;
  /**
   * Enables resizing of the content.
   * @group Props
   */
  resizable;
  /**
   * Enables dragging to change the position using header.
   * @group Props
   */
  draggable;
  /**
   * Keeps dialog in the viewport.
   * @group Props
   */
  keepInViewport;
  /**
   * Minimum value for the left coordinate of dialog in dragging.
   * @group Props
   */
  minX;
  /**
   * Minimum value for the top coordinate of dialog in dragging.
   * @group Props
   */
  minY;
  /**
   * Whether the dialog can be displayed full screen.
   * @group Props
   */
  maximizable;
  /**
   * Name of the maximize icon.
   * @group Props
   */
  maximizeIcon;
  /**
   * Name of the minimize icon.
   * @group Props
   */
  minimizeIcon;
  /**
   * Position of the dialog, options are "center", "top", "bottom", "left", "right", "top-left", "top-right", "bottom-left" or "bottom-right".
   * @group Props
   */
  position;
  /**
   * Defines a string that labels the close button for accessibility.
   * @group Props
   */
  closeAriaLabel;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo;
  /**
   * A boolean to determine if it can be duplicate.
   * @group Props
   */
  duplicate;
  /**
   * Object literal to define widths per screen size.
   * @group Props
   */
  breakpoints;
  /**
   * Dialog templates.
   * @group Props
   */
  templates;
}

/**
 * Dynamic Dialog instance.
 * @group Components
 */
class DynamicDialogRef {
  constructor() {}
  /**
   * Closes dialog.
   * @group Method
   */
  close(result) {
    this._onClose.next(result);
    setTimeout(() => {
      this._onClose.complete();
    }, 1000);
  }
  /**
   * Destroys the dialog instance.
   * @group Method
   */
  destroy() {
    this._onDestroy.next(null);
  }
  /**
   * Callback to invoke on drag start.
   * @param {MouseEvent} event - Mouse event.
   * @group Method
   */
  dragStart(event) {
    this._onDragStart.next(event);
  }
  /**
   * Callback to invoke on drag end.
   * @param {MouseEvent} event - Mouse event.
   * @group Method
   */
  dragEnd(event) {
    this._onDragEnd.next(event);
  }
  /**
   * Callback to invoke on resize start.
   * @param {MouseEvent} event - Mouse event.
   * @group Method
   */
  resizeInit(event) {
    this._onResizeInit.next(event);
  }
  /**
   * Callback to invoke on resize start.
   * @param {MouseEvent} event - Mouse event.
   * @group Method
   */
  resizeEnd(event) {
    this._onResizeEnd.next(event);
  }
  /**
   * Callback to invoke on dialog is maximized.
   * @param {*} value - Size value.
   * @group Method
   */
  maximize(value) {
    this._onMaximize.next(value);
  }
  _onClose = new Subject();
  /**
   * Event triggered on dialog is closed.
   * @group Events
   */
  onClose = this._onClose.asObservable();
  _onDestroy = new Subject();
  /**
   * Event triggered on dialog instance is destroyed.
   * @group Events
   */
  onDestroy = this._onDestroy.asObservable();
  _onDragStart = new Subject();
  /**
   * Event triggered on drag start.
   * @param {MouseEvent} event - Mouse event.
   * @group Events
   */
  onDragStart = this._onDragStart.asObservable();
  _onDragEnd = new Subject();
  /**
   * Event triggered on drag end.
   * @param {MouseEvent} event - Mouse event.
   * @group Events
   */
  onDragEnd = this._onDragEnd.asObservable();
  _onResizeInit = new Subject();
  /**
   * Event triggered on resize start.
   * @param {MouseEvent} event - Mouse event.
   * @group Events
   */
  onResizeInit = this._onResizeInit.asObservable();
  _onResizeEnd = new Subject();
  /**
   * Event triggered on resize end.
   * @param {MouseEvent} event - Mouse event.
   * @group Events
   */
  onResizeEnd = this._onResizeEnd.asObservable();
  _onMaximize = new Subject();
  /**
   * Event triggered on dialog is maximized.
   * @param {*} value - Size value.
   * @group Events
   */
  onMaximize = this._onMaximize.asObservable();
  /**
   * Event triggered on child component load.
   * @param {*} value - Chi.
   * @group Events
   */
  onChildComponentLoaded = new Subject();
}
const showAnimation = animation([style({
  transform: '{{transform}}',
  opacity: 0
}), animate('{{transition}}', style({
  transform: 'none',
  opacity: 1
}))]);
const hideAnimation = animation([animate('{{transition}}', style({
  transform: '{{transform}}',
  opacity: 0
}))]);
class DynamicDialogComponent extends BaseComponent {
  renderer;
  ddconfig;
  dialogRef;
  zone;
  parentDialog;
  visible = true;
  componentRef;
  mask;
  resizing;
  dragging;
  maximized;
  _style = {};
  originalStyle;
  lastPageX;
  lastPageY;
  ariaLabelledBy;
  id = UniqueComponentId();
  styleElement;
  insertionPoint;
  maskViewChild;
  contentViewChild;
  footerViewChild;
  headerViewChild;
  childComponentType;
  container;
  wrapper;
  documentKeydownListener;
  documentEscapeListener;
  maskClickListener;
  transformOptions = 'scale(0.7)';
  documentResizeListener;
  documentResizeEndListener;
  documentDragListener;
  documentDragEndListener;
  _componentStyle = inject(DynamicDialogStyle);
  get minX() {
    return this.ddconfig.minX ? this.ddconfig.minX : 0;
  }
  get minY() {
    return this.ddconfig.minY ? this.ddconfig.minY : 0;
  }
  get keepInViewport() {
    return this.ddconfig.keepInViewport;
  }
  get maximizable() {
    return this.ddconfig.maximizable;
  }
  get maximizeIcon() {
    return this.ddconfig.maximizeIcon;
  }
  get minimizeIcon() {
    return this.ddconfig.minimizeIcon;
  }
  get closable() {
    return this.ddconfig.closable;
  }
  get style() {
    return this._style;
  }
  get position() {
    return this.ddconfig.position;
  }
  get closeAriaLabel() {
    return this.config.getTranslation(TranslationKeys.ARIA)['close'];
  }
  set style(value) {
    if (value) {
      this._style = {
        ...value
      };
      this.originalStyle = value;
    }
  }
  get parent() {
    const domElements = Array.from(this.document.getElementsByClassName('p-dialog'));
    if (domElements.length > 1) {
      return domElements.pop();
    }
  }
  get parentContent() {
    const domElements = Array.from(this.document.getElementsByClassName('p-dialog'));
    if (domElements.length > 0) {
      const contentElements = domElements[domElements.length - 1].querySelector('.p-dialog-content');
      if (contentElements) return Array.isArray(contentElements) ? contentElements[0] : contentElements;
    }
  }
  get header() {
    return this.ddconfig.header;
  }
  get data() {
    return this.ddconfig.data;
  }
  get breakpoints() {
    return this.ddconfig.breakpoints;
  }
  get footerTemplate() {
    return this.ddconfig?.templates?.footer;
  }
  get headerTemplate() {
    return this.ddconfig?.templates?.header;
  }
  get contentTemplate() {
    return this.ddconfig?.templates?.content;
  }
  get minimizeIconTemplate() {
    return this.ddconfig?.templates?.minimizeicon;
  }
  get maximizeIconTemplate() {
    return this.ddconfig?.templates?.maximizeicon;
  }
  get closeIconTemplate() {
    return this.ddconfig?.templates?.closeicon;
  }
  constructor(renderer, ddconfig, dialogRef, zone, parentDialog) {
    super();
    this.renderer = renderer;
    this.ddconfig = ddconfig;
    this.dialogRef = dialogRef;
    this.zone = zone;
    this.parentDialog = parentDialog;
  }
  ngOnInit() {
    super.ngOnInit();
    if (this.breakpoints) {
      this.createStyle();
    }
  }
  createStyle() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.styleElement) {
        this.styleElement = this.renderer.createElement('style');
        this.styleElement.type = 'text/css';
        this.renderer.appendChild(this.document.head, this.styleElement);
        let innerHTML = '';
        for (let breakpoint in this.breakpoints) {
          innerHTML += `
                        @media screen and (max-width: ${breakpoint}) {
                            .p-dialog[${this.id}]:not(.p-dialog-maximized) {
                                width: ${this.breakpoints[breakpoint]} !important;
                            }
                        }
                    `;
        }
        this.renderer.setProperty(this.styleElement, 'innerHTML', innerHTML);
        DomHandler.setAttribute(this.styleElement, 'nonce', this.config?.csp()?.nonce);
      }
    }
  }
  destroyStyle() {
    if (this.styleElement) {
      this.renderer.removeChild(this.document.head, this.styleElement);
      this.styleElement = null;
    }
  }
  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.ariaLabelledBy = this.getAriaLabelledBy();
    this.cd.detectChanges();
  }
  getAriaLabelledBy() {
    return this.header !== null ? UniqueComponentId() + '_header' : null;
  }
  loadChildComponent(componentType) {
    let viewContainerRef = this.insertionPoint?.viewContainerRef;
    viewContainerRef?.clear();
    this.componentRef = viewContainerRef?.createComponent(componentType);
    this.dialogRef.onChildComponentLoaded.next(this.componentRef.instance);
  }
  moveOnTop() {
    if (this.ddconfig.autoZIndex !== false) {
      ZIndexUtils.set('modal', this.container, (this.ddconfig.baseZIndex || 0) + this.config.zIndex.modal);
      this.wrapper.style.zIndex = String(parseInt(this.container.style.zIndex, 10) - 1);
    }
  }
  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.wrapper = this.container.parentElement;
        this.moveOnTop();
        if (this.parent) {
          this.unbindGlobalListeners();
        }
        this.bindGlobalListeners();
        this.container?.setAttribute(this.id, '');
        if (this.ddconfig.modal !== false) {
          this.enableModality();
        }
        if (this.ddconfig.focusOnShow !== false) {
          this.focus();
        }
        break;
      case 'void':
        if (this.wrapper && this.ddconfig.modal !== false) {
          DomHandler.addClass(this.wrapper, 'p-overlay-mask-leave');
        }
        break;
    }
  }
  onAnimationEnd(event) {
    if (event.toState === 'void') {
      if (this.parentContent) {
        this.focus(this.parentContent);
      }
      this.onContainerDestroy();
      this.dialogRef.destroy();
    }
  }
  onContainerDestroy() {
    this.unbindGlobalListeners();
    if (this.container && this.ddconfig.autoZIndex !== false) {
      ZIndexUtils.clear(this.container);
    }
    if (this.ddconfig.modal !== false) {
      this.disableModality();
    }
    this.container = null;
  }
  close() {
    this.visible = false;
    this.cd.markForCheck();
  }
  hide() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  enableModality() {
    if (this.ddconfig.dismissableMask) {
      this.maskClickListener = this.renderer.listen(this.wrapper, 'mousedown', event => {
        if (this.wrapper && this.wrapper.isSameNode(event.target)) {
          this.hide();
        }
      });
    }
    if (this.ddconfig.modal !== false) {
      DomHandler.addClass(this.document.body, 'p-overflow-hidden');
    }
  }
  disableModality() {
    if (this.wrapper) {
      if (this.ddconfig.dismissableMask) {
        this.unbindMaskClickListener();
      }
      if (this.ddconfig.modal !== false) {
        DomHandler.removeClass(this.document.body, 'p-overflow-hidden');
      }
      if (!this.cd.destroyed) {
        this.cd.detectChanges();
      }
    }
  }
  focus(focusParentElement = this.contentViewChild.nativeElement) {
    let focusable = DomHandler.getFocusableElement(focusParentElement, '[autofocus]');
    if (focusable) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => focusable.focus(), 5);
      });
      return;
    }
    const focusableElement = DomHandler.getFocusableElement(focusParentElement);
    if (focusableElement) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => focusableElement.focus(), 5);
      });
    } else if (this.footerViewChild) {
      // If the content section is empty try to focus on footer
      this.focus(this.footerViewChild.nativeElement);
    } else if (!focusableElement && this.headerViewChild) {
      this.focus(this.headerViewChild.nativeElement);
    }
  }
  maximize() {
    this.maximized = !this.maximized;
    if (this.maximized) {
      DomHandler.addClass(this.document.body, 'p-overflow-hidden');
    } else {
      DomHandler.removeClass(this.document.body, 'p-overflow-hidden');
    }
    this.dialogRef.maximize({
      maximized: this.maximized
    });
  }
  initResize(event) {
    if (this.ddconfig.resizable) {
      if (!this.documentResizeListener) {
        this.bindDocumentResizeListeners();
      }
      this.resizing = true;
      this.lastPageX = event.pageX;
      this.lastPageY = event.pageY;
      DomHandler.addClass(this.document.body, 'p-unselectable-text');
      this.dialogRef.resizeInit(event);
    }
  }
  onResize(event) {
    if (this.resizing) {
      let deltaX = event.pageX - this.lastPageX;
      let deltaY = event.pageY - this.lastPageY;
      let containerWidth = DomHandler.getOuterWidth(this.container);
      let containerHeight = DomHandler.getOuterHeight(this.container);
      let contentHeight = DomHandler.getOuterHeight(this.contentViewChild.nativeElement);
      let newWidth = containerWidth + deltaX;
      let newHeight = containerHeight + deltaY;
      let minWidth = this.container.style.minWidth;
      let minHeight = this.container.style.minHeight;
      let offset = this.container.getBoundingClientRect();
      let viewport = DomHandler.getViewport();
      let hasBeenDragged = !parseInt(this.container.style.top) || !parseInt(this.container.style.left);
      if (hasBeenDragged) {
        newWidth += deltaX;
        newHeight += deltaY;
      }
      if ((!minWidth || newWidth > parseInt(minWidth)) && offset.left + newWidth < viewport.width) {
        this._style.width = newWidth + 'px';
        this.container.style.width = this._style.width;
      }
      if ((!minHeight || newHeight > parseInt(minHeight)) && offset.top + newHeight < viewport.height) {
        this.contentViewChild.nativeElement.style.height = contentHeight + newHeight - containerHeight + 'px';
        if (this._style.height) {
          this._style.height = newHeight + 'px';
          this.container.style.height = this._style.height;
        }
      }
      this.lastPageX = event.pageX;
      this.lastPageY = event.pageY;
    }
  }
  resizeEnd(event) {
    if (this.resizing) {
      this.resizing = false;
      DomHandler.removeClass(this.document.body, 'p-unselectable-text');
      this.dialogRef.resizeEnd(event);
    }
  }
  initDrag(event) {
    if (DomHandler.hasClass(event.target, 'p-dialog-header-icon') || DomHandler.hasClass(event.target.parentElement, 'p-dialog-header-icon')) {
      return;
    }
    if (this.ddconfig.draggable) {
      this.dragging = true;
      this.lastPageX = event.pageX;
      this.lastPageY = event.pageY;
      this.container.style.margin = '0';
      DomHandler.addClass(this.document.body, 'p-unselectable-text');
      this.dialogRef.dragStart(event);
    }
  }
  onDrag(event) {
    if (this.dragging) {
      let containerWidth = DomHandler.getOuterWidth(this.container);
      let containerHeight = DomHandler.getOuterHeight(this.container);
      let deltaX = event.pageX - this.lastPageX;
      let deltaY = event.pageY - this.lastPageY;
      let offset = this.container.getBoundingClientRect();
      let leftPos = offset.left + deltaX;
      let topPos = offset.top + deltaY;
      let viewport = DomHandler.getViewport();
      this.container.style.position = 'fixed';
      if (this.keepInViewport) {
        if (leftPos >= this.minX && leftPos + containerWidth < viewport.width) {
          this._style.left = leftPos + 'px';
          this.lastPageX = event.pageX;
          this.container.style.left = leftPos + 'px';
        }
        if (topPos >= this.minY && topPos + containerHeight < viewport.height) {
          this._style.top = topPos + 'px';
          this.lastPageY = event.pageY;
          this.container.style.top = topPos + 'px';
        }
      } else {
        this.lastPageX = event.pageX;
        this.container.style.left = leftPos + 'px';
        this.lastPageY = event.pageY;
        this.container.style.top = topPos + 'px';
      }
    }
  }
  endDrag(event) {
    if (this.dragging) {
      this.dragging = false;
      DomHandler.removeClass(this.document.body, 'p-unselectable-text');
      this.dialogRef.dragEnd(event);
      this.cd.detectChanges();
    }
  }
  resetPosition() {
    this.container.style.position = '';
    this.container.style.left = '';
    this.container.style.top = '';
    this.container.style.margin = '';
  }
  bindDocumentDragListener() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.documentDragListener = this.renderer.listen(this.document, 'mousemove', this.onDrag.bind(this));
      });
    }
  }
  bindDocumentDragEndListener() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.documentDragEndListener = this.renderer.listen(this.document, 'mouseup', this.endDrag.bind(this));
      });
    }
  }
  unbindDocumentDragEndListener() {
    if (this.documentDragEndListener) {
      this.documentDragEndListener();
      this.documentDragListener = null;
    }
  }
  unbindDocumentDragListener() {
    if (this.documentDragListener) {
      this.documentDragListener();
      this.documentDragListener = null;
    }
  }
  bindDocumentResizeListeners() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.documentResizeListener = this.renderer.listen(this.document, 'mousemove', this.onResize.bind(this));
        this.documentResizeEndListener = this.renderer.listen(this.document, 'mouseup', this.resizeEnd.bind(this));
      });
    }
  }
  unbindDocumentResizeListeners() {
    if (this.documentResizeListener && this.documentResizeEndListener) {
      this.documentResizeListener();
      this.documentResizeEndListener();
      this.documentResizeListener = null;
      this.documentResizeEndListener = null;
    }
  }
  bindGlobalListeners() {
    if (this.ddconfig.closeOnEscape !== false) {
      this.bindDocumentEscapeListener();
    }
    if (this.ddconfig.resizable) {
      this.bindDocumentResizeListeners();
    }
    if (this.ddconfig.draggable) {
      this.bindDocumentDragListener();
      this.bindDocumentDragEndListener();
    }
  }
  unbindGlobalListeners() {
    this.unbindDocumentEscapeListener();
    this.unbindDocumentResizeListeners();
    this.unbindDocumentDragListener();
    this.unbindDocumentDragEndListener();
  }
  bindDocumentEscapeListener() {
    const documentTarget = this.maskViewChild ? this.maskViewChild.nativeElement.ownerDocument : 'document';
    this.documentEscapeListener = this.renderer.listen(documentTarget, 'keydown', event => {
      if (event.which == 27) {
        if (parseInt(this.container.style.zIndex) == ZIndexUtils.getCurrent()) {
          this.hide();
        }
      }
    });
  }
  unbindDocumentEscapeListener() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }
  unbindMaskClickListener() {
    if (this.maskClickListener) {
      this.maskClickListener();
      this.maskClickListener = null;
    }
  }
  ngOnDestroy() {
    this.onContainerDestroy();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.destroyStyle();
    super.ngOnDestroy();
  }
  static ɵfac = function DynamicDialogComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DynamicDialogComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(DynamicDialogConfig), i0.ɵɵdirectiveInject(DynamicDialogRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(DynamicDialogComponent, 12));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: DynamicDialogComponent,
    selectors: [["p-dynamicDialog"]],
    viewQuery: function DynamicDialogComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(DynamicDialogContent, 5);
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5);
        i0.ɵɵviewQuery(_c2, 5);
        i0.ɵɵviewQuery(_c3, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.insertionPoint = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.maskViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerViewChild = _t.first);
      }
    },
    features: [i0.ɵɵProvidersFeature([DynamicDialogStyle]), i0.ɵɵInheritDefinitionFeature],
    decls: 3,
    vars: 5,
    consts: [["mask", ""], ["container", ""], ["content", ""], ["titlebar", ""], ["footer", ""], [3, "ngClass", "ngStyle"], ["role", "dialog", "pFocusTrap", "", 3, "ngClass", "ngStyle", "style", "class", "pFocusTrapDisabled", "width", "height", 4, "ngIf"], ["role", "dialog", "pFocusTrap", "", 3, "ngClass", "ngStyle", "pFocusTrapDisabled"], ["style", "z-index: 90;", 3, "ngClass", "mousedown", 4, "ngIf"], [3, "ngClass", "mousedown", 4, "ngIf"], [4, "ngIf"], [4, "ngComponentOutlet"], [3, "ngClass", 4, "ngIf"], [2, "z-index", "90", 3, "mousedown", "ngClass"], [3, "mousedown", "ngClass"], [3, "ngClass", "id"], [3, "ngClass"], [3, "styleClass", "tabindex", "onClick", "keydown.enter", 4, "ngIf"], ["rounded", "", "text", "", "severity", "secondary", 3, "styleClass", "ariaLabel", "onClick", "keydown.enter", 4, "ngIf"], [3, "onClick", "keydown.enter", "styleClass", "tabindex"], [4, "ngTemplateOutlet"], ["rounded", "", "text", "", "severity", "secondary", 3, "onClick", "keydown.enter", "styleClass", "ariaLabel"], ["pDynamicDialogContent", ""]],
    template: function DynamicDialogComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 5, 0);
        i0.ɵɵtemplate(2, DynamicDialogComponent_div_2_Template, 9, 26, "div", 6);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.ddconfig.maskStyleClass);
        i0.ɵɵproperty("ngClass", ctx.cx("mask"))("ngStyle", ctx.sx("mask"));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.visible);
      }
    },
    dependencies: () => [i3.NgClass, i3.NgComponentOutlet, i3.NgIf, i3.NgTemplateOutlet, i3.NgStyle, WindowMaximizeIcon, WindowMinimizeIcon, TimesIcon, i4.Button, i5.FocusTrap, DynamicDialogContent],
    encapsulation: 2,
    data: {
      animation: [trigger('animation', [transition('void => visible', [useAnimation(showAnimation)]), transition('visible => void', [useAnimation(hideAnimation)])])]
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicDialogComponent, [{
    type: Component,
    args: [{
      selector: 'p-dynamicDialog',
      template: `
        <div #mask [ngClass]="cx('mask')" [ngStyle]="sx('mask')" [class]="ddconfig.maskStyleClass">
            <div
                *ngIf="visible"
                #container
                [ngClass]="cx('root')"
                [ngStyle]="sx('root')"
                [style]="ddconfig.style"
                [class]="ddconfig.styleClass"
                [@animation]="{
                    value: 'visible',
                    params: {
                        transform: transformOptions,
                        transition: ddconfig.transitionOptions || '150ms cubic-bezier(0, 0, 0.2, 1)',
                    },
                }"
                (@animation.start)="onAnimationStart($event)"
                (@animation.done)="onAnimationEnd($event)"
                role="dialog"
                pFocusTrap
                [pFocusTrapDisabled]="ddconfig.focusTrap === false"
                [style.width]="ddconfig.width"
                [style.height]="ddconfig.height"
                [attr.aria-labelledby]="ariaLabelledBy"
                [attr.aria-modal]="true"
            >
                <div *ngIf="ddconfig.resizable" [ngClass]="cx('resizeHandle')" style="z-index: 90;" (mousedown)="initResize($event)"></div>
                <div #titlebar [ngClass]="cx('header')" (mousedown)="initDrag($event)" *ngIf="ddconfig.showHeader === false ? false : true">
                    <ng-container *ngComponentOutlet="headerTemplate"></ng-container>
                    <ng-container *ngIf="!headerTemplate">
                        <span [ngClass]="cx('title')" [id]="ariaLabelledBy">{{ ddconfig.header }}</span>
                        <div [ngClass]="cx('headerActions')">
                            <p-button
                                *ngIf="ddconfig.maximizable"
                                [styleClass]="cx('pcMaximizeButton')"
                                (onClick)="maximize()"
                                (keydown.enter)="maximize()"
                                [tabindex]="maximizable ? '0' : '-1'"
                            >
                                <ng-container *ngIf="!maximizeIcon">
                                    <WindowMaximizeIcon *ngIf="!maximized && !maximizeIconTemplate" />
                                    <WindowMinimizeIcon *ngIf="maximized && !minimizeIconTemplate" />
                                </ng-container>
                                <ng-container *ngIf="!maximized">
                                    <ng-template *ngTemplateOutlet="_maximizeIconTemplate"></ng-template>
                                </ng-container>
                                <ng-container *ngIf="maximized">
                                    <ng-template *ngTemplateOutlet="_minimizeIconTemplate"></ng-template>
                                </ng-container>
                            </p-button>
                            <p-button
                                *ngIf="closable"
                                [styleClass]="cx('pcCloseButton')"
                                [ariaLabel]="closeAriaLabel"
                                (onClick)="hide()"
                                (keydown.enter)="hide()"
                                rounded
                                text
                                severity="secondary"
                            >
                                <ng-container *ngIf="!closeIconTemplate">
                                    <TimesIcon />
                                </ng-container>
                                <span *ngIf="closeIconTemplate">
                                    <ng-template *ngTemplateOutlet="closeIconTemplate"></ng-template>
                                </span>
                            </p-button>
                        </div>
                    </ng-container>
                </div>
                <div #content [ngClass]="cx('content')" [ngStyle]="ddconfig.contentStyle">
                    <ng-template pDynamicDialogContent *ngIf="!contentTemplate"></ng-template>
                    <ng-container *ngComponentOutlet="contentTemplate"></ng-container>
                </div>
                <div #footer [ngClass]="cx('footer')" *ngIf="ddconfig.footer || footerTemplate">
                    <ng-container *ngIf="!footerTemplate">
                        {{ ddconfig.footer }}
                    </ng-container>
                    <ng-container *ngComponentOutlet="footerTemplate"></ng-container>
                </div>
            </div>
        </div>
    `,
      animations: [trigger('animation', [transition('void => visible', [useAnimation(showAnimation)]), transition('visible => void', [useAnimation(hideAnimation)])])],
      changeDetection: ChangeDetectionStrategy.Default,
      encapsulation: ViewEncapsulation.None,
      providers: [DynamicDialogStyle]
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: DynamicDialogConfig
  }, {
    type: DynamicDialogRef
  }, {
    type: i0.NgZone
  }, {
    type: DynamicDialogComponent,
    decorators: [{
      type: SkipSelf
    }, {
      type: Optional
    }]
  }], {
    insertionPoint: [{
      type: ViewChild,
      args: [DynamicDialogContent]
    }],
    maskViewChild: [{
      type: ViewChild,
      args: ['mask']
    }],
    contentViewChild: [{
      type: ViewChild,
      args: ['content']
    }],
    footerViewChild: [{
      type: ViewChild,
      args: ['footer']
    }],
    headerViewChild: [{
      type: ViewChild,
      args: ['titlebar']
    }]
  });
})();
class DynamicDialogModule {
  static ɵfac = function DynamicDialogModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DynamicDialogModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: DynamicDialogModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, WindowMaximizeIcon, WindowMinimizeIcon, TimesIcon, ButtonModule, SharedModule, FocusTrapModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicDialogModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, WindowMaximizeIcon, WindowMinimizeIcon, TimesIcon, ButtonModule, SharedModule, FocusTrapModule],
      declarations: [DynamicDialogComponent, DynamicDialogContent],
      exports: [SharedModule]
    }]
  }], null, null);
})();
class DynamicDialogInjector {
  _parentInjector;
  _additionalTokens;
  constructor(_parentInjector, _additionalTokens) {
    this._parentInjector = _parentInjector;
    this._additionalTokens = _additionalTokens;
  }
  get(token, notFoundValue, options) {
    const value = this._additionalTokens.get(token);
    if (value) return value;
    return this._parentInjector.get(token, notFoundValue);
  }
}

/**
 * Dynamic Dialog component methods.
 * @group Service
 */
class DialogService {
  appRef;
  injector;
  document;
  dialogComponentRefMap = new Map();
  constructor(appRef, injector, document) {
    this.appRef = appRef;
    this.injector = injector;
    this.document = document;
  }
  /**
   * Displays the dialog using the dynamic dialog object options.
   * @param {*} componentType - Dynamic component for content template.
   * @param {DynamicDialogConfig} config - DynamicDialog object.
   * @returns {DynamicDialogRef} DynamicDialog instance.
   * @group Method
   */
  open(componentType, config) {
    if (!this.duplicationPermission(componentType, config)) {
      return null;
    }
    const dialogRef = this.appendDialogComponentToBody(config, componentType);
    this.dialogComponentRefMap.get(dialogRef).instance.childComponentType = componentType;
    return dialogRef;
  }
  /**
   * Returns the dynamic dialog component instance.
   * @param {ref} DynamicDialogRef - DynamicDialog instance.
   * @group Method
   */
  getInstance(ref) {
    return this.dialogComponentRefMap.get(ref).instance;
  }
  appendDialogComponentToBody(config, componentType) {
    const map = new WeakMap();
    map.set(DynamicDialogConfig, config);
    const dialogRef = new DynamicDialogRef();
    map.set(DynamicDialogRef, dialogRef);
    const sub = dialogRef.onClose.subscribe(() => {
      this.dialogComponentRefMap.get(dialogRef).instance.close();
    });
    const destroySub = dialogRef.onDestroy.subscribe(() => {
      this.removeDialogComponentFromBody(dialogRef);
      destroySub.unsubscribe();
      sub.unsubscribe();
    });
    const componentRef = createComponent(DynamicDialogComponent, {
      environmentInjector: this.appRef.injector,
      elementInjector: new DynamicDialogInjector(this.injector, map)
    });
    this.appRef.attachView(componentRef.hostView);
    const domElem = componentRef.hostView.rootNodes[0];
    if (!config.appendTo || config.appendTo === 'body') {
      this.document.body.appendChild(domElem);
    } else {
      DomHandler.appendChild(domElem, config.appendTo);
    }
    this.dialogComponentRefMap.set(dialogRef, componentRef);
    return dialogRef;
  }
  removeDialogComponentFromBody(dialogRef) {
    if (!dialogRef || !this.dialogComponentRefMap.has(dialogRef)) {
      return;
    }
    const dialogComponentRef = this.dialogComponentRefMap.get(dialogRef);
    this.appRef.detachView(dialogComponentRef.hostView);
    dialogComponentRef.destroy();
    this.dialogComponentRefMap.delete(dialogRef);
  }
  duplicationPermission(componentType, config) {
    if (config.duplicate) {
      return true;
    }
    let permission = true;
    for (const [key, value] of this.dialogComponentRefMap) {
      if (value.instance.childComponentType === componentType) {
        permission = false;
        break;
      }
    }
    return permission;
  }
  static ɵfac = function DialogService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DialogService)(i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(DOCUMENT));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DialogService,
    factory: DialogService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogService, [{
    type: Injectable
  }], () => [{
    type: i0.ApplicationRef
  }, {
    type: i0.Injector
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogInjector, DynamicDialogModule, DynamicDialogRef };
