<p-toast></p-toast>
<div class="grid grid-cols-2 bg-surface-0 mb-8">
  <div>
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
    <div class="flex items-start flex-col lg:justify-between lg:flex-row">
      <div>
        <div class="font-medium text-4xl">{{title}}</div>
        <ng-content select="[branch]"></ng-content>
      </div>
    </div>
  </div>
  <div>
    <div class="mt-4 lg:mt-0 flex flex-row justify-end float-end">
      <ng-content select="button"></ng-content>
<!--      <usecsv-button-->
<!--        importerKey="7130ce65-ab57-40a1-8074-fda91ff38670"-->
<!--        [onData]="onDataCallback.bind(this)"-->
<!--        *ngIf="showEmployeeImportButton"-->
<!--      >-->
<!--        <button class="flatfile-button ms-2" customUsecsvButton>Import</button>-->
<!--      </usecsv-button>-->
    </div>
  </div>
  <div class="col-span-2">
    <ng-content select="[eventDashboardButtons]"></ng-content>
  </div>
</div>
<hr />
